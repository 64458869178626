const illustrations = document.querySelectorAll('[data-ill-name]');
const activeIllustrationClassName = 'hero-illustration-image__display';

let currentActiveIllustration = -1;

function changeIllustration() {
    currentActiveIllustration ++;

    if (currentActiveIllustration > illustrations.length - 1) {
        currentActiveIllustration = 0;
    }

    illustrations.forEach((illustration, index) => {
        if (index === currentActiveIllustration) {
            illustration.classList.add(activeIllustrationClassName);
        } else {
            illustration.classList.remove(activeIllustrationClassName);
        }
    });
}

changeIllustration();

setInterval(changeIllustration, 5000);